<template>
  <v-text-field :label="label" v-model="myValue" v-facade="inputMask" :outlined="outlined" :rules="rules">
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"></slot>
    </template>
  </v-text-field>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { facade } from 'vue-input-facade'

export default {
  directives: { facade },
  props: {
    label: {
      type: String,
      default: 'Time'
    },
    mask: {
      type: [ String, Array ],
      required: true
    },
    value: String,
    outlined: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const myValue = ref('')
    watch(() => props.value, () => {
      myValue.value = props.value
    })
    watch(myValue, (val) => {
      if (props.value !== val) emit('input', val)
    })

    const inputMask = computed(() => {
      if (props.mask.substr(0, 1) === '[') return JSON.parse(props.mask)
      else return props.mask
    })
    return {
      myValue,
      inputMask
    }
  }
}
</script>
